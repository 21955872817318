import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "../layout/Layout";
import About from "../routes/About";
import Portfolio from "../routes/Portfolio";
import Contact from "../routes/Contact";
import Interests from "../routes/Interests";

const BurgerMenuNav: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Render the layout for all pages except the landing page */}
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<About />} /> {/* Landing page */}
          <Route path="about" element={<About />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="interests" element={<Interests />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default BurgerMenuNav;
