import React from 'react';
import "../styles/CardComponent.css";

const CardComponent: React.FC = () => {
  return (
    <div className="card">
      <img src="/arildFroslee.JPG" alt="User Image" />
      <div>
        <h2>Arild Frøslie</h2>
        <h3>Frontend Developer</h3>
            <p>
            Just a guy who loves to work with frontend development - web development and native apps! 
            <br/>
            <br/>
            Please have a look around my humble page so you can get to know me better!
            </p>
      </div>
    </div>
  );
};

export default CardComponent;
