import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../styles/ContactForm.css';

function ContactForm() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSent, setIsSent] = useState(false);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const name = formData.get("name") as string;
    const email = formData.get("email") as string;
    const message = formData.get("message") as string;

    if (!name.trim() || !email.trim() || !message.trim()) {
      setErrorMessage("Please fill out all fields.");
      return;
    }

    formData.append("access_key", "f89e6c7c-9a04-46ea-85cd-ed1612024e09");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      setIsSent(true);
      toast.success("Email sent successfully!");
      console.log("Success", res);
    }
  };

  return (
    <div className="formCard">
      <form onSubmit={onSubmit}>
        <p className="formName"> Please write your name..</p>
        <input type="text" name="name" placeholder="Your name goes here"/>

        <p className="formEmail"> ..Email..</p>
        <input type="email" name="email" placeholder="Your email.."/>

        <p className="formMessage"> .. and your message goes here</p>
        <textarea name="message" placeholder="Write me something nice!"></textarea>

        {errorMessage && <p className="errorMessage">{errorMessage}</p>}

        <button type="submit" className="formBtn">Submit Form</button>
      </form>

      <ToastContainer />
    </div>
  );
}

export default ContactForm;
