import React, { useState } from 'react';
import '../styles/Accordion.css';

interface Item {
  image: string;
  header: string;
  text: string;
}

interface AccordionProps {
  items: Item[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [active, setActive] = useState<number | null>(null);

  const handleToggle = (index: number) => setActive(index === active ? null : index);

  return (
    <section className="image-accordion">
      {items.map((item, index) => {
        const isActive = active === index ? 'active' : '';
        return (
          <div
            key={item.image}
            className={`image-accordion-item ${isActive}`}
            onClick={() => handleToggle(index)}
          >
            <img 
            src={item.image} 
            alt={item.header} 
            id={item.image === 'thewitcher.jpeg' ? 'geralt' : ''}/>
            <div className="content">
              <div>
                <h2>{item.header}</h2>
                <p>{item.text}</p>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Accordion;
