export const AccordionGames = [
    {
      image: 'gow.webp',
      header: 'God of War (2018)',
      text: 'The soft reboot of the classic trilogy. I was moved by the personal growth of the infamous Ghost of Sparta.'
    },
    {
      image: 'thewitcher.jpeg',
      header: 'The Witcher 3',
      text: 'This game is easy to get lost in. The world is incredible, the stories, sidequests and DLC is absolutely a benchmark in videogame writing!'
    },
    {
      image: 'sv.avif',
      header: 'Stardew Valley' ,
      text: 'Such a small game, with so much soul. I have had a lot of fun with this. And it also got couch coop!'
    },
    {
      image: 'hd2.avif',
      header: 'Helldivers 2' ,
      text: 'Maybe the greatest coop shooter ever made? Incredibly fun, and extremely satisfying gameplay.'
    },
    {
      image: 'aw2.avif',
      header: 'Alan Wake 2' ,
      text: 'What a journey. Spooky, creepy, weird, meta and Lynchian. A masterpiece in storytelling - both visually and technically.'
    },
    {
      image: 'ely.webp',
      header: 'Disco Elysium' ,
      text: 'The best game I have read. This game is super fun and well written. Will you be a superstar cop, or an intellectual Freud-wannabe? You decide!'
    },
    {
      image: 'er.avif',
      header: 'Elden Ring' ,
      text: 'I enjoyed Elden Ring a lot - the exploration, the lore, the environments, and level design. No doubt deserving of all its awards'
    },
    {
      image: 'got.png',
      header: 'Ghost of Tsushima' ,
      text: 'I love graphics - and this game delivered stunning graphics and a great story of how Jin Sakai abandoned his values to become what his people needed.'
    },
  ];
