import react from 'react';
import CardComponent from '../cards/CardComponent';

const About: React.FC = () => (
  <div>
  <CardComponent/>
  </div>
  
  
);

export default About;