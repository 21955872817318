import React from 'react';
import '../styles/Grid.css';

interface GridProps {
  rows: number;
  columns: number;
}

const Grid: React.FC<GridProps> = ({ rows, columns }) => {

  const techStackImages = [
    'react.png',
    'vue.png',
    'ts.webp',
    'swift.png',
    'big3.png',
    'java.svg',
    'koitlin.png',
    'sql.png',
    'csharp.png',
  ];
  const gridItems = [];
  let index = 0;
  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < columns; j++) {
      const imageSrc = techStackImages[index % techStackImages.length];
      gridItems.push(
        <div key={`${i}-${j}`} className="grid-item">
          <img src={imageSrc} alt={`Tech ${index}`} />
        </div>
      );
      index++;
    }
  }

  return <div className="grid-container">{gridItems}</div>;
};

export default Grid;
