import React from 'react';
import Accordion from '../accordion/Accordion';
import { AccordionGames } from '../accordion/AccordionGames';

const Interests: React.FC = () => {
  return (
    <div>
      <Accordion items={AccordionGames} />
    </div>
  );
};

export default Interests;
