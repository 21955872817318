import react from 'react';

import Grid from '../grid/Grid';

const Portfolio: React.FC = () => (
  <div>

      
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid rows={3} columns={3} />
        </div>
      </div>
);

export default Portfolio;