import React from 'react';
import './components/styles/BurgerMenuNav.css'
import BurgerMenuNav from './components/navigation/BurgerMenuNav';
import '../src/App.css'

function App() {
  return (
    <div>
      <BurgerMenuNav />

    </div>
  );
}

export default App;